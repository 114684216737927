<template>
    <div class="Box_index" 
        :class="this.$route.meta.session==false&&this.$route.name=='StudentPages'?'Index_headers':this.$route.meta.session==false&&this.$route.name!='StudentPages'?'Box_indexs':this.$route.meta.session==true?'session_B':''">
        <div class="Index_header" >
            <div class="selec_left">
                <img v-if="this.$route.meta.session==false" class="l_span" src="./../../assets/img/logo.png" alt="" @click="homePage(1)">
                <img v-else class="l_span" src="./../../assets/img/logo1.png" alt="" @click="homePage(2)">
                <div class="countdown" v-show="this.$route.meta.requiresAuth==true && countNum==1">
                    倒计时
                    <span class="count_span">
                        {{timer}}
                    </span>
                </div>
            </div>
            <div class="header_right">
                <div class="top_info" ref="main">
                    <div class="top_info_div" v-show="this.$route.meta.info==true">
                        <div class="_i_div_xx">
                            <i class="iconfonts icons-xinxi53" @click="infoBox()"></i>
                        </div>
                    </div>
                    <div class="menu_one" v-show="this.$route.meta.qrCode==true">
                        <el-tooltip class="item" effect="dark" content="资料库" placement="bottom">
                            <i class="iconfonts icons-icon_folder" @click="drawers()"></i>
                        </el-tooltip>
                    </div>
                    <div class="top_info_div" v-show="this.$route.meta.qrCode==true">
                        <div class="_i_div">
                            <i class="iconfonts icons-QRcode"></i>
                        </div>
                        <div class="popver_div">
                            <div class="ppi_inier">
                                <img class="img_code" :src="Qrcode" alt="">
                            </div>
                        </div>
                    </div>
                    <img class="head_img" :src="this.$store.state.user_avatar===null ||this.$store.state.user_avatar==''||this.$store.state.user_avatar=='null'?'https://static.ibeisai.com/image/default_avatar.png':this.$store.state.user_avatar" alt="" @click="BoxImg()">
                    <transition name="fade">
                        <ul class="user_info"  v-if="bankSwitch==true">
                            <li @click="cenBox()">个人中心</li>
                            <li @click="joinBox()">加入课堂</li>
                            <li><hr style="height:2px; background: #ccc; border:0; margin-top:6px;"/></li>
                            <li @click="exits()" style="color:#EB1C27;">退出登录</li>
                        </ul>
                    </transition>
                </div>
            </div>
        </div>
        <div class="Index_conter">
            <router-view @info="valuess"></router-view>
        </div>
        <!-- 加入课堂弹窗 -->
        <div class="sroom_Popup">
            <el-dialog
                title="加入课堂"
                :visible.sync="dialogVisible"
                :show-close="false"
                width="450px"
                :before-close="handleClose"
            >
                <i class="el-icon-close closei" @click="closei('ruleForm')"></i>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" @submit.native.prevent>
                    <el-form-item label="课堂码：" prop="code">
                        <el-input v-model="ruleForm.code" placeholder="请输入课堂码"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button size="small" v-preventReClick @click="closei('ruleForm')">取消</el-button>
                    <el-button type="primary" size="small" v-preventReClick @click="rolexit('ruleForm')">确定</el-button>
                </span>
                <el-dialog
                        width="450px"
                        title="选择小组"
                        custom-class="sroom_Popup"
                        :visible.sync="innerVisible"
                        :before-close="handleClose"
                        :show-close="false"
                        append-to-body>
                        <i class="el-icon-close closei" @click="closeis('ruleForms')"></i>
                        <el-form ref="ruleForms" :model="ruleForms" :rules="rules" @submit.native.prevent>
                            <el-form-item label="选择小组：" prop="id">
                                <el-select v-model="ruleForms.id" placeholder="请选择要加入的组">
                                    <el-option
                                        v-for="(item,index) in teamList"
                                        :key="index"
                                        :label='item.name'
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                        <span slot="footer" class="dialog-footer">
                            <el-button size="small" v-preventReClick @click="closeis('ruleForms')">取 消</el-button>
                            <el-button size="small" type="primary" v-preventReClick @click="addteam('ruleForms','ruleForm')">确 定</el-button>
                        </span>
                    </el-dialog>
            </el-dialog>
            <el-dialog
                title="课程信息"
                :visible.sync="infoVisible"
                :show-close="false"
                width="450px"
                :before-close="handleClose"
            >
                <i class="el-icon-close closei" @click="infoclose()"></i>
                <div class="xm_xinix">
                    <p class="xm_title">课程名称</p>
                    <div class="xm_span">
                        <span>{{info.course.name}}</span>
                    </div>
                    <p class="xm_title">团队组别</p>
                    <div class="xm_span">
                        <span>{{info.team}}</span>
                    </div>
                    <p class="xm_titles">
                        <span>成员列表</span>
                        <i class="iconfonts icons-tuandui"></i>
                        <span>{{info.users.length}}名成员</span>
                    </p>
                    <ul class="xm_ul clearfix">
                        <li class="fl" v-for="(item,index) in info.users" :key="index">
                            <div class="div_img">
                                <img class="div_img_img" v-show="item.type==1" src="./../../assets/img/hgBox.png" alt="">
                                <img class="div_img_img1" :src="item.avatar" alt="">
                            </div>
                            <span>{{item.name}}</span>
                        </li>
                    </ul>
                </div>
            </el-dialog>
        </div>
        <div class="drawerBox">
            <el-drawer
                size="450px"
                title="资料库"
                :visible.sync="drawer"
                :with-header="true">
                <div class="mu_list">
                    <ul>
                        <li v-for="(item,index) in drawList" :key="index" v-show="drawList.length!=0">
                            <div class="bm-drake-box">
                                <div class="mu-item">
                                    <div class="mu-item-left">
                                        <div class="mu-avatar">
                                            <div class="mu-avatar-inner">
                                                <img :src="item.thumb" alt="">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mu-item_item clearfix">
                                        <div class="item_left fl">
                                            <span>{{item.name}}</span>
                                        </div>
                                        <div class="item_right fr">
                                            <i class="iconfonts icons-xiazai" v-preventReClick @click="download(item)"></i>
                                        </div>
                                    </div>
                                    <hr class="mu-divider">
                                </div>
                            </div>
                        </li>
                        <div class="default-wrapper" v-show="drawList.length==0">
                            <img src="./../../assets/img/bookmark.svg" alt="">
                            <p><span>暂无数据</span></p>
                        </div>
                    </ul>
                </div>
            </el-drawer>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            drawer:false,
            drawList:[],
            Qrcode:'',
            bankSwitch:false,
            dialogVisible:false,
            infoVisible:false,
            innerVisible:false,
            teamList:[],
            countNum:0,
            timer:'',
            ruleForms:{
                id:'',
            },
            ruleForm:{
                code:"",
            },
            rules:{
                code:[
                    { required: true, message: '请输入六位课堂码'},
                    {pattern:/^\d+$/, message:'课堂码必须为数字值'}
                ],
                id:[
                    { required: true, message: '请选择小组', trigger:'change'},
                ],
            },
            info:{
                course:{},
                users:[],
                team:''
            },
        }
    },

    methods: {
        // 查询课程信息
        infoBox(){
            this.axios.StuCourseinfo({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    this.infoVisible = true
                    this.info = res.data.data
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        },
        //加入课堂
        rolexit(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.axios.StuCoursecode({
                        token:this.$store.state.token,
                        code:this.ruleForm.code
                    }).then(res=>{
                        if(res.data.code==0){
                            if(res.data.data.type==1){
                                this.axios.StuCoursejoin({
                                    token:this.$store.state.token,
                                    code:this.ruleForm.code,
                                }).then(res=>{
                                    this.dialogVisible= false
                                    this.$refs[formName].resetFields();
                                    this.$router.push({name:'StudentPage'})
                                }).catch(err=>{

                                })
                            }else{
                                this.cla_id = res.data.data.id
                                this.teamList = res.data.data.teams
                                this.innerVisible = true
                            }
                        }else{
                            this.$message.error(res.data.message)
                        }
                    }).catch(err=>{

                    })
                }
            })
        },
        valuess(data){
            this.Qrcode = data
        },
        homePage(val){
            if(val==1){
                this.$router.push({name:'StudentPage'})
            }else if(val==2){
                this.$router.push({name:'Bhomepage'})
            }
            
        },
        // 打开资料库
        drawers(){
            this.axios.StuCoursedoc({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    this.bankSwitch = false
                    this.drawList = res.data.data
                    this.drawer = true
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        },
        // 资料库下载
        download(item){
            window.open(item.url, "_blank");
        }, 
        // 选择小组
        addteam(formName,ruleForm){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.axios.StuCoursejoin({
                        token:this.$store.state.token,
                        code:this.ruleForm.code,
                        id:this.ruleForms.id
                    }).then(res=>{
                        if(res.data.code==0){
                            this.innerVisible = false
                            this.dialogVisible= false
                            this.$refs[formName,ruleForm].resetFields();
                            this.$store.dispatch("cla_id",this.cla_id)
                            this.$router.push({name:'StudentPage'})
                        }else{
                            this.$message.error(res.data.message)
                        }
                    }).catch(err=>{

                    })
                }
            })
        },
        infoclose(){
            this.infoVisible = false
        },
        // 关闭弹窗
        closei(formName){
            this.dialogVisible = false
            this.innerVisible = false
            this.$refs[formName].resetFields();
        },
        closeis(formName){
            this.innerVisible = false
            this.$refs[formName].resetFields();
        },
        BoxImg(){
            if(this.bankSwitch==true){
                this.bankSwitch = false
            }else{
                this.bankSwitch = true
            }
        },
        // 个人中心
        cenBox(){
            this.$router.push({name:'Personalcenter'})
            this.bankSwitch = false
        },
        //加入课堂
        joinBox(){
            this.dialogVisible = true
            this.bankSwitch = false
        },
        feedBack(){
            this.$router.push({name:'Userfeedback'})
            this.bankSwitch = false
        },
        bodyCloseMenus(e){
            let self = this;
            if (this.$refs.main && !this.$refs.main.contains(e.target)) {
                if (self.bankSwitch == true){
                    self.bankSwitch = false;
                }
            }
        },
        //退出登录
        exits(){
            this.axios.userlogout({
                token:this.$store.state.token
            }).then(res=>{
                if(res.data.code==0){
                    this.$router.push('/login')
                    localStorage.clear()
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        },
        handleClose(done){

        },
            // 获取倒计时
        down(){
            this.axios.StudctdGet({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    if(res.data.data!='' && res.data.data!=null && res.data.data!='null'){
                        this.countNum = 1
                        this.count = res.data.data
                        this.time = res.data.data.times
                        if(this.count.state==1){
                            this.countDown()
                        }else{
                            let h = parseInt(this.time / (60 * 60) % 24);
                            h = h < 10 ? "0" + h : h
                            let m = parseInt(this.time / 60 % 60);
                            m = m < 10 ? "0" + m : m
                            let s = parseInt(this.time % 60);
                            s = s < 10 ? "0" + s : s
                            this.timer = h +':' + m +':'+ s
                            this.timeoutss = setTimeout(this.down,5000)
                        }
                    }else{
                        this.timeout = setTimeout(this.down,3000)
                        this.countNum = 2
                    }
                }else if(res.data.code==-4054){
                    this.timeout = setTimeout(this.down,3000)
                    this.countNum = 2
                    // this.$message.error(res.data.message)
                }else{
                  this.countNum = 2
                }
            }).catch(err=>{

            })
        },
        //倒计时
        countDown(){
            if(this.time>0){
                let h = parseInt(this.time / (60 * 60) % 24);
                h = h < 10 ? "0" + h : h
                let m = parseInt(this.time / 60 % 60);
                m = m < 10 ? "0" + m : m
                let s = parseInt(this.time % 60);
                s = s < 10 ? "0" + s : s
                this.timer = h +':'+ m +':'+ s
                this.time--
                this.timeouts = setTimeout(this.countDown,1000)
            }else{
                this.timer = '00' +':'+ '00' +':'+ '00'
            }
        },
    },
    beforeDestroy () {
        let that = this;
        clearTimeout(that.timeout);
        clearTimeout(that.timeouts);
        clearTimeout(that.timeoutss)
        that.timeout = null;
        that.timeouts = null;
        that.timeoutss = null;
    },
    destroyed () {
        let that = this;
        clearTimeout(that.timeout);
        clearTimeout(that.timeouts);
        clearTimeout(that.timeoutss)
        that.timeout = null;
        that.timeouts = null;
        that.timeoutss = null;
    },
    mounted() {
        document.addEventListener("click", this.bodyCloseMenus);
        if(this.$route.meta.requiresAuth==true){
            this.down()
        }
    },
    beforeDestroy() {
        document.removeEventListener("click", this.bodyCloseMenus);
    },
};
</script>
<style scoped>
@import './../../assets/css/student/index.css';
</style>
<style>
.drawerBox .el-drawer__header{
    padding: 0 8px;
    height: 44px;
    line-height: 44px;
    background: #f1f6fe;
    color: #0e1726;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
}
.fade-enter,.fade-leave-to{
    opacity: 0;
    transform: translateY(-15px);
}
.fade-enter-active,.fade-leave-active{
    transition: 0.3s cubic-bezier(0.25,0.8,0.5,1)!important;
}
.sroom_Popup .el-dialog__title{
    font-size: 16px;
    color: #fff;
}
.sroom_Popup .el-dialog__header{
    padding: 10px 20px;
}
.sroom_Popup.el-dialog{
    border-radius: 6px;
    background: rgba(25, 36, 105, 1);
    border: 1px solid #34FFFF;
}
.sroom_Popup .el-dialog{
    border-radius: 6px;
    background: rgba(25, 36, 105, 1);
    border: 1px solid #34FFFF;
}
.sroom_Popup .el-dialog__body{
    padding: 20px 40px 10px;
}
.sroom_Popup .el-dialog__footer{
    text-align: center;
}
.sroom_Popup .el-button--small{
    width: 100px;
    height: 32px;
    font-size: 14px;
    line-height: 32px;
    padding: 0;
}
.sroom_Popup .el-select{
    width: 100%;
}
.sroom_Popup .el-form-item__label{
    line-height: 30px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
}
</style>